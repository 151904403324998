/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { createTheme } from '@fluentui/theme';
import { CommonSemanticColors, LightSemanticColors } from '@altair/a365-themes-amdc';
import { StyleConstants } from '@altair/a365-themes-amdc';
import { AOneUnityStyleSettings } from '@altair/a365-themes-amdc';

const nonLightExtendedSemanticColors = {
  errorText: CommonSemanticColors.errorText,

  inputBackgroundSelected: CommonSemanticColors.input.inputBackgroundSelected,
  inputBorderDisabled: CommonSemanticColors.input.inputBorderDisabled,
  inputBorderFocused: CommonSemanticColors.input.inputBorderFocused,
  inputBorder: CommonSemanticColors.input.inputBorder,

  inputBackgroundChecked: CommonSemanticColors.input.inputBackgroundChecked,
  inputBackgroundCheckedHovered: CommonSemanticColors.input.inputBackgroundCheckedHovered,
  smallInputBorder: CommonSemanticColors.input.smallInputBorder,

  contextualMenuBorder: LightSemanticColors.contextualMenu.border,
  contextualMenuColor: LightSemanticColors.contextualMenu.color,
  contextualMenuIconColor: LightSemanticColors.contextualMenu.iconColor,
  contextualMenuDisabledColor: LightSemanticColors.contextualMenu.disabledColor,

  //defaultButtonBackground: LightSemanticColors.secondaryButton.rest.background,
  //defaultButtonBackgroundDisabled: LightSemanticColors.secondaryButton.disabled.background,
  defaultButtonBackgroundHovered: LightSemanticColors.secondaryButton.hover.background,
  defaultButtonBackgroundPressed: LightSemanticColors.secondaryButton.pressed.background,
  defaultButtonBorder: LightSemanticColors.secondaryButton.rest.border,
  defaultButtonBorderDisabled: LightSemanticColors.secondaryButton.disabled.border,
  defaultButtonBorderHovered: LightSemanticColors.secondaryButton.hover.border,
  defaultButtonBorderPressed: LightSemanticColors.secondaryButton.pressed.border,
  //defaultButtonText: LightSemanticColors.secondaryButton.rest.text,
  defaultButtonTextDisabled: LightSemanticColors.secondaryButton.disabled.text,
  defaultButtonTextHovered: LightSemanticColors.secondaryButton.hover.text,
  defaultButtonTextPressed: LightSemanticColors.secondaryButton.pressed.text,

  detailsListContentBorder: LightSemanticColors.list.content.border,
  //detailsListContentHoverBackground: LightSemanticColors.list.content.hover.background,
  detailsListContentHoverSelectedBackground: LightSemanticColors.list.content.selectedHover.background,
  //detailsListContentSelectedBackground: LightSemanticColors.list.content.selected.background,
  //detailsListContentText: LightSemanticColors.list.content.text,
  detailsListHeaderBorder: LightSemanticColors.list.header.border,
  //detailsListHeaderText: LightSemanticColors.list.header.text,

  //dialogText: LightSemanticColors.dialog.text,
  dialogSubText: LightSemanticColors.dialog.subText,
  //iconColor: LightSemanticColors.icon.color,
  iconBackground: LightSemanticColors.icon.background,

  //labelText: LightSemanticColors.label.text,

  linkText: LightSemanticColors.link.linkText,
  linkVisitedText: LightSemanticColors.link.visitedText,

  navBackground: LightSemanticColors.nav.rest.background,
  navSelectedBackground: LightSemanticColors.nav.selected.background,
  navSelectedText: LightSemanticColors.nav.selected.text,
  navText: LightSemanticColors.nav.rest.text,

  //pivotBackgroundHover: LightSemanticColors.pivot.hover.background,
  //pivotSelectedBorderBottom: LightSemanticColors.pivot.selected.border,
  pivotText: LightSemanticColors.pivot.rest.text,

  //primaryButtonBackground: LightSemanticColors.primaryButton.rest.background,
  //primaryButtonBackgroundDisabled: LightSemanticColors.primaryButton.disabled.background,
  primaryButtonBackgroundHovered: LightSemanticColors.primaryButton.hover.background,
  primaryButtonBackgroundPressed: LightSemanticColors.primaryButton.pressed.background,
  primaryButtonBorder: LightSemanticColors.primaryButton.rest.border,
  //primaryButtonBorderHovered: LightSemanticColors.primaryButton.hover.border,
  primaryButtonBorderDisabled: LightSemanticColors.primaryButton.disabled.border,
  primaryButtonBorderPressed: LightSemanticColors.primaryButton.pressed.border,
  primaryButtonText: LightSemanticColors.primaryButton.rest.text,
  primaryButtonTextDisabled: LightSemanticColors.primaryButton.disabled.text,
  //primaryButtonTextHovered: LightSemanticColors.primaryButton.hover.text,
  primaryButtonTextPressed: LightSemanticColors.primaryButton.pressed.text,

  progressBarBackground: LightSemanticColors.progressIndicator.progressBarBackground,

  searchBorderFocused: LightSemanticColors.search.borderFocused,

  tagIconColor: LightSemanticColors.tag.iconColor,
  tagIconBackground: LightSemanticColors.tag.iconBackground,

  //commandBarMenuIcon: LightSemanticColors.commandBarIconStyles.rest,
  commandBarMenuHoverBackgroundColor: LightSemanticColors.commandBarIconStyles.hoverBackground,
  commandBarMenuIconDisabled: LightSemanticColors.commandBarIconStyles.disabled,
};

const lightExtendedSemanticColors = {
  errorText: CommonSemanticColors.errorText,

  inputBackgroundSelected: CommonSemanticColors.input.inputBackgroundSelected,
  inputBorderDisabled: CommonSemanticColors.input.inputBorderDisabled,
  inputBorderFocused: CommonSemanticColors.input.inputBorderFocused,
  inputBorder: CommonSemanticColors.input.inputBorder,

  inputBackgroundChecked: CommonSemanticColors.input.inputBackgroundChecked,
  inputBackgroundCheckedHovered: CommonSemanticColors.input.inputBackgroundCheckedHovered,
  smallInputBorder: CommonSemanticColors.input.smallInputBorder,

  contextualMenuBorder: LightSemanticColors.contextualMenu.border,
  contextualMenuColor: LightSemanticColors.contextualMenu.color,
  contextualMenuIconColor: LightSemanticColors.contextualMenu.iconColor,
  contextualMenuDisabledColor: LightSemanticColors.contextualMenu.disabledColor,

  defaultButtonBackground: LightSemanticColors.secondaryButton.rest.background,
  defaultButtonBackgroundDisabled: LightSemanticColors.secondaryButton.disabled.background,
  defaultButtonBackgroundHovered: LightSemanticColors.secondaryButton.hover.background,
  defaultButtonBackgroundPressed: LightSemanticColors.secondaryButton.pressed.background,
  defaultButtonBorder: LightSemanticColors.secondaryButton.rest.border,
  defaultButtonBorderDisabled: LightSemanticColors.secondaryButton.disabled.border,
  defaultButtonBorderHovered: LightSemanticColors.secondaryButton.hover.border,
  defaultButtonBorderPressed: LightSemanticColors.secondaryButton.pressed.border,
  defaultButtonText: LightSemanticColors.secondaryButton.rest.text,
  defaultButtonTextDisabled: LightSemanticColors.secondaryButton.disabled.text,
  defaultButtonTextHovered: LightSemanticColors.secondaryButton.hover.text,
  defaultButtonTextPressed: LightSemanticColors.secondaryButton.pressed.text,

  detailsListContentBorder: LightSemanticColors.list.content.border,
  detailsListContentHoverBackground: LightSemanticColors.list.content.hover.background,
  detailsListContentHoverSelectedBackground: LightSemanticColors.list.content.selectedHover.background,
  detailsListContentSelectedBackground: LightSemanticColors.list.content.selected.background,
  detailsListContentText: LightSemanticColors.list.content.text,
  detailsListHeaderBorder: LightSemanticColors.list.header.border,
  detailsListHeaderText: LightSemanticColors.list.header.text,

  dialogText: LightSemanticColors.dialog.text,
  dialogSubText: LightSemanticColors.dialog.subText,
  iconColor: LightSemanticColors.icon.color,
  iconBackground: LightSemanticColors.icon.background,

  labelText: LightSemanticColors.label.text,

  linkText: LightSemanticColors.link.linkText,
  linkVisitedText: LightSemanticColors.link.visitedText,

  navBackground: LightSemanticColors.nav.rest.background,
  navSelectedBackground: LightSemanticColors.nav.selected.background,
  navSelectedText: LightSemanticColors.nav.selected.text,
  navText: LightSemanticColors.nav.rest.text,

  pivotBackgroundHover: LightSemanticColors.pivot.hover.background,
  pivotSelectedBorderBottom: LightSemanticColors.pivot.selected.border,
  pivotText: LightSemanticColors.pivot.rest.text,

  primaryButtonBackground: LightSemanticColors.primaryButton.rest.background,
  primaryButtonBackgroundDisabled: LightSemanticColors.primaryButton.disabled.background,
  primaryButtonBackgroundHovered: LightSemanticColors.primaryButton.hover.background,
  primaryButtonBackgroundPressed: LightSemanticColors.primaryButton.pressed.background,
  primaryButtonBorder: LightSemanticColors.primaryButton.rest.border,
  primaryButtonBorderHovered: LightSemanticColors.primaryButton.hover.border,
  primaryButtonBorderDisabled: LightSemanticColors.primaryButton.disabled.border,
  primaryButtonBorderPressed: LightSemanticColors.primaryButton.pressed.border,
  primaryButtonText: LightSemanticColors.primaryButton.rest.text,
  primaryButtonTextDisabled: LightSemanticColors.primaryButton.disabled.text,
  primaryButtonTextHovered: LightSemanticColors.primaryButton.hover.text,
  primaryButtonTextPressed: LightSemanticColors.primaryButton.pressed.text,

  progressBarBackground: LightSemanticColors.progressIndicator.progressBarBackground,

  searchBorderFocused: LightSemanticColors.search.borderFocused,

  tagIconColor: LightSemanticColors.tag.iconColor,
  tagIconBackground: LightSemanticColors.tag.iconBackground,

  commandBarMenuIcon: LightSemanticColors.commandBarIconStyles.rest,
  commandBarMenuHoverBackgroundColor: LightSemanticColors.commandBarIconStyles.hoverBackground,
  commandBarMenuIconDisabled: LightSemanticColors.commandBarIconStyles.disabled,
  filterButtonBorder: 'transparent',
  filterButtonBackground: '#E7552F',
  waffleButtonIcon: '#005776',
  headerBackgroundColor: '#f3f4f6',
  filterHeaderText: '#3B6C83',
  filterGroupExpanderText: '#3B6C83',
  clearAllFiltersButtonBackground: '#2DCCD3',
  clearAllFiltersButton: '#FFFFFF',
  filterButtonText: '#FFFFFF',
  collapsibleGroupText: '#3B6C83',
  filterButtonClose: '#FFFFFF',
  bodyBackground: '#FFFFFF',
  tileHeaderText: '#005776',
  textBlueLink: '#005776',
  landingLeftBackground: '#005776',
  landingLeftText: '#FFFFFF',
  errorBackground: '#FFFFFF',
  plotModebarColor: 'gray',
  spinnerCircleColor: '#AAAAAA #005776 #005776',
  spinnerLabelColor: 'gray',
  errorMessageBorderColor: 'red',
  filterButtonTextHovered: LightSemanticColors.label.text,
};

let defaultFallbackColors = {
  inputText: 'bodyText',
  menuItemText: 'bodyText',
  listText: 'bodyText',
  labelText: 'bodyText',
  defaultButtonText: 'bodyText',
  iconColor: 'bodyText',
  inputPlaceholderText: 'bodyText',
  buttonBackground: 'bodyBackground',
  primaryButtonBackground: 'bodyBackground',
  primaryButtonBackgroundDisabled: 'bodyBackground',
  menuBackground: 'bodyBackground',
  listBackground: 'bodyBackground',
  inputBackground: 'bodyBackground',
  defaultButtonBackground: 'bodyBackground',
  iconBackground: 'bodyBackground',
  buttonBackgroundDisabled: 'bodyBackground',
  defaultStateBackground: 'bodyBackground',
  disabledBackground: 'bodyBackground',
  defaultButtonBackgroundDisabled: 'bodyBackground',
  filterButtonBackground: 'footerBackground',
  commandBarMenuIcon: 'bodyText',
  waffleButtonIcon: 'bodyText',
  commandBarMenuHoverBackgroundColor: 'bodyBackground',
  dialogText: 'bodyText',
  primaryButtonBackgroundHovered: 'footerBackground',
  //headerBackgroundColor: '#323232',
  //inputTextHovered: '#8A8A8A',
  buttonTextHovered: 'inputTextHovered',
  detailsListContentText: 'bodyText',
  detailsListHeaderText: 'bodyText',
  //detailsListContentSelectedBackground: '#1F1F1F',
  pivotSelectedBorderBottom: 'bodyText',
  pivotBackgroundHover: 'footerBackground',
  plotTickColor: 'bodyText',
  plotAxisTitleColor: 'bodyText',
  plotTitleColor: 'bodyText',
  plotLegendColor: 'bodyText',
  inputBorderHovered: 'bodyText',
  //detailsListContentHoverBackground: '#7A7A7A',
  polarChartText: 'bodyText',
  menuItemBackgroundHovered: 'footerBackground',
  menuItemBackgroundPressed: 'footerBackground',
  filterButtonTextHovered: 'bodyText',
  linkText: 'bodyText',
  linkTextHovered: 'bodyText',
  linkVisitedText: 'bodyText',
  primaryButtonTextHovered: 'bodyText',
  primaryButtonBorderHovered: 'bodyText',
  tileHeaderText: 'textBlueLink',
  tileActionButtonText: 'textBlueLink',
  tileActionButtonIcon: 'textBlueLink',
  filterGroupExpanderTextHovered: 'textBlueLink',
  impactAssessmentRowBackground: 'footerBackground',
  //textBlueLink: '#3ab2de',
  landingLeftBackground: 'bodyBackground',
  landingLeftText: 'bodyText',
  errorBackground: 'bodyBackground',
  plotModebarColor: 'gray',
  footerBackground: 'footerBackground',
  footerLink: 'bodyText',
  footerLinkVisited: 'bodyText',
  footerLinkHovered: 'bodyText',
  footerAppDetails: 'bodyText',
  footerText: 'bodyText',
  spinnerLabelColor: 'bodyText',
};

export function updateThemeWithFallback(semanticColors, theme, configFallbackColors = null) {
  let fallbackColors = { ...defaultFallbackColors, ...configFallbackColors };

  let semanticColorsWithFallback = {};
  if (theme.toLowerCase().includes('light')) {
    semanticColorsWithFallback = { ...lightExtendedSemanticColors, ...semanticColors };
  } else {
    semanticColorsWithFallback = { ...nonLightExtendedSemanticColors, ...semanticColors };
  }

  for (const [key, value] of Object.entries(fallbackColors)) {
    if (!semanticColorsWithFallback[key] && semanticColorsWithFallback[value]) {
      semanticColorsWithFallback[key] = semanticColorsWithFallback[value];
    }
  }

  return semanticColorsWithFallback;
}
export function createUnityTheme(semanticColors) {
  const customTheme = createTheme({
    defaultFontStyle: {
      fontFamily: StyleConstants.fontFamily,
    },
    fonts: {
      medium: {
        fontFamily: StyleConstants.fontFamily,
      },
    },

    semanticColors: semanticColors,
  });

  customTheme.components = AOneUnityStyleSettings(customTheme);

  return customTheme;
}
