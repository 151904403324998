/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner, SpinnerSize } from '@fluentui/react';
import PropTypes from 'prop-types';
import * as TextMapping from '../../utils/textMapping';

function InfiniteScrolling({ children, firstLoad, dataLength, fetchMoreData, hasMore, isFetching, scrollableTarget, texts }) {
  const ref = useRef(null);

  useEffect(() => {
    if (scrollableTarget) {
      const contentListHeight = ref.current.clientHeight;
      const scrollableTargetHeight = scrollableTarget.clientHeight
        ? scrollableTarget.clientHeight
        : document.getElementById(scrollableTarget).offsetHeight;

      // Fetch more data if there is no scrollbar yet
      if (!isFetching && hasMore === true && contentListHeight < scrollableTargetHeight) {
        fetchMoreData();
      }
    }
  }, [dataLength, hasMore, scrollableTarget, isFetching, fetchMoreData]);

  return (
    <InfiniteScroll
      scrollableTarget={scrollableTarget}
      dataLength={dataLength}
      next={fetchMoreData}
      hasMore={hasMore}
      style={{ overflow: 'visible' }}
      loader={
        <div
          style={{
            margin: '50px',
            textAlign: 'center',
          }}
        >
          {!firstLoad && isFetching && (
            <Spinner size={SpinnerSize.large} label={TextMapping.getUIText(TextMapping.UI_TEXT_LOADING_CONTENTS, texts)} />
          )}
        </div>
      }
      endMessage={null}
    >
      <div ref={ref}>{children}</div>
    </InfiniteScroll>
  );
}

InfiniteScrolling.propTypes = {
  children: PropTypes.node.isRequired,
  dataLength: PropTypes.number.isRequired,
  fetchMoreData: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  scrollableTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Element)]).isRequired,
};

export default InfiniteScrolling;
