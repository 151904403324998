/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { DefaultButton, IconBase } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { DefaultButtonStyle, Divider, GroupExpanderRoot } from './styled';
import { onRenderGroupExpanderButtonText } from './onRenderGroupExpanderButtonText';

const NOOP = () => {};

function GroupExpander(props) {
  const { children, isActive, isExpanded: propsIsExpanded, onResetFilter, text, group, appContent, ...restProps } = props;
  const isExpandClickedRef = useRef(propsIsExpanded);
  const [isExpanded, setIsExpanded] = useState(propsIsExpanded);
  const iconName = isExpanded ? 'ChevronDown' : 'ChevronRight';

  const STATE_ID = `GroupExpander-${appContent?.shortname}-${group.id}`;
  const componentRef = useRef(null);
  const [height, setHeight] = useState(localStorage.getItem(STATE_ID) || 'auto'); // Initial height
  const MIN_HEIGHT = 52;

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (componentRef.current) {
      const newHeight = e.clientY - componentRef.current.getBoundingClientRect().top;
      if (newHeight >= MIN_HEIGHT) {
        setHeight(newHeight);
      }
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    if (appContent && appContent.shortname && appContent.config?.showfiltergroupresize) {
      if (localStorage.getItem(STATE_ID)) {
        setHeight(localStorage.getItem(STATE_ID));
      }
    }
  }, [STATE_ID, appContent]);

  useEffect(() => {
    if (height !== 'auto' && appContent && appContent.shortname) {
      localStorage.setItem(STATE_ID, height);
    }
  }, [height, STATE_ID, appContent]);

  useEffect(() => {
    if (!isExpandClickedRef.current) {
      setIsExpanded(propsIsExpanded);
    }
  }, [propsIsExpanded]);

  function handleClick() {
    isExpandClickedRef.current = true;
    setIsExpanded(!isExpanded);
  }

  function handleResetFilter(e) {
    e.preventDefault();
    e.stopPropagation();

    onResetFilter(group);
  }

  let componentHeight = 'auto';

  if (isExpanded && height !== 'auto') {
    componentHeight = `${height}px`;
  }

  return (
    <GroupExpanderRoot
      ref={componentRef}
      className="Filter__SubHeader"
      style={{ height: isExpanded ? componentHeight : '24px', position: 'relative' }}
      {...restProps}
    >
      <Divider />
      <DefaultButton
        className="filterGroupExpanderText"
        data-testid="GroupExpander-Button"
        iconProps={{ iconName }}
        onRenderIcon={() => (
          <>
            <IconBase iconName={iconName} styles={{ root: { fontSize: 16 } }} />
            {isActive && (
              <div
                aria-roledescription="Rest Filter"
                className="aone-GroupExpander-reset"
                onKeyPress={NOOP}
                onClick={handleResetFilter}
                role="button"
                tabIndex={-1}
                title="Clear Filters"
              >
                Clear
              </div>
            )}
          </>
        )}
        onClick={handleClick}
        onRenderText={onRenderGroupExpanderButtonText}
        styles={DefaultButtonStyle}
        text={text}
      />

      <div
        style={{
          height: isExpanded ? 'auto' : 0,
          display: isExpanded ? 'block' : 'none',
        }}
      >
        {React.cloneElement(children, { parentHeight: height })}
      </div>

      {isExpanded && appContent?.config?.showfiltergroupresize && (
        <div
          style={{
            height: '10px',
            cursor: 'ns-resize',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            zIndex: 10000,
            backgroundColor: 'transparent',
          }}
          onMouseDown={handleMouseDown}
        />
      )}
    </GroupExpanderRoot>
  );
}

GroupExpander.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onResetFilter: PropTypes.func,
  text: PropTypes.string,
};

GroupExpander.defaultProps = {
  isActive: false,
  isExpanded: false,
  onResetFilter: () => {},
  text: '',
};

export default React.memo(GroupExpander);
