import React, { useEffect, useRef, useState } from 'react';
import { VerticalTabs } from '../VerticalTabs';
import { VerticalTabItem } from '../VerticalTabs/VerticalTabItem';
import { Pivot, PivotItem } from '@fluentui/react';
import { processBuildingBlock } from '../processBuildingBlock';
import RoundIconButton from '../../gooey/components/RoundIconButton';
import { processFormat } from '../processBuildingBlock';

const pivotStyles = {
  itemContainer: {
    flexGrow: 1,
    overflowY: 'auto',
  },
};

export default function Tabs({
  orientation,
  format,
  name,
  id,
  breakpoint,
  onTabClick,
  getActiveTab,
  childContent,
  appContent,
  windowSize,
  processCheckbox,
  processIcon,
  handleToggler,
  collapsedVkeys,
  canAccess,
  onDownloadClick,
  handleTextAction,
  data,
  layoutMap,
  setLayoutMap,
  handleContactUsClick,
  handleNoDriveLicenseClick,
  webSocket,
  addSearch,
  addCompare,
  downloadPDF,
  downloadXLS,
  addStyle,
  ranges,
  handleWaffleButtonClick,
  handleNotificationBellClick,
  handleUserButtonClick,
  addFeedback,
  addBlocksPage,
  texts,
}) {
  const [width, setWidth] = useState();
  let tabsParent = useRef();

  useEffect(() => {
    let refNode = tabsParent.current;
    const resizeObserver = new ResizeObserver((ele) => {
      setWidth(ele[0].contentRect.width);
    });
    if (tabsParent.current) {
      resizeObserver.observe(tabsParent.current);
    }
    return () => refNode && resizeObserver.unobserve(refNode);
  }, [tabsParent]);

  const addTab = (body, buildingBlock, childBody) => {
    let tabComponent = null;
    const testid = buildingBlock.data.name;

    let breakpoint = 500;
    if (buildingBlock.data.breakpoint) {
      breakpoint = buildingBlock.data.breakpoint;
    }

    if (orientation === 'horizontal' || width < breakpoint) {
      let sanitizedName = buildingBlock.data.name ? buildingBlock.data.name.replace('<br>', ' / ') : '';
      sanitizedName = sanitizedName.replace(/<[^>]*>?/gm, '').trim();

      tabComponent = (
        <PivotItem
          className="h-full"
          itemKey={buildingBlock.data.id}
          data-testid={testid}
          headerText={sanitizedName}
          onScroll={() => {
            let el = document.querySelector(`[data-testid="${testid}"]`);
            if (el) {
              let button = document.getElementById(`scroll-top-button-${testid}`);

              let minYPosition = appContent?.config?.scrolltotop?.minYPosition ? appContent.config.scrolltotop.minYPosition : 100;

              if (el.scrollTop > minYPosition) {
                if (button) {
                  button.style.display = 'block';
                }
              } else {
                if (button) {
                  button.style.display = 'none';
                }
              }
            }
          }}
        >
          <div className={'h-full ' + processFormat(buildingBlock.data.format) + ' w-full'}>{childBody}</div>
          <div
            id={`scroll-top-button-${testid}`}
            style={{
              position: 'fixed',
              bottom: appContent?.config?.scrolltotop?.bottom ? appContent.config.scrolltotop.bottom : '75px',
              right: appContent?.config?.scrolltotop?.right ? appContent.config.scrolltotop.right : '50px',
              display: 'none',
              opacity: appContent?.config?.scrolltotop?.opacity ? appContent.config.scrolltotop.opacity : 0.8,
            }}
          >
            <RoundIconButton
              iconProps={{ iconName: appContent?.config?.scrolltotop?.iconName ? appContent.config.scrolltotop.iconName : 'Up' }}
              onClick={() => {
                let el = document.querySelector(`[data-testid="${testid}"]`);
                if (el) {
                  el.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }
              }}
            />
          </div>
        </PivotItem>
      );
    } else {
      tabComponent = (
        <VerticalTabItem
          data-testid={`building-block-tab-${buildingBlock.data.name}`}
          name={buildingBlock.data.name}
          format={processFormat(buildingBlock.data.format)}
        >
          {childBody}
        </VerticalTabItem>
      );
    }

    body.push(tabComponent);
  };

  let childBody = [];
  for (let child of childContent) {
    processBuildingBlock({
      buildingBlock: child,
      body: childBody,
      windowSize,
      processCheckbox,
      processIcon,
      handleToggler,
      collapsedVkeys,
      onTabClick,
      getActiveTab,
      tabOrientation: orientation,
      canAccess,
      onDownloadClick,
      handleTextAction,
      data,
      layoutMap,
      setLayoutMap,
      handleContactUsClick,
      handleNoDriveLicenseClick,
      webSocket,
      addSearch,
      addCompare,
      downloadPDF,
      downloadXLS,
      addStyle,
      ranges,
      handleWaffleButtonClick,
      handleNotificationBellClick,
      handleUserButtonClick,
      addFeedback,
      addBlocksPage,
      appContent,
      texts,
      addTab,
    });
  }

  let tabsComponent = null;

  if (!breakpoint) {
    breakpoint = 500;
  }

  if (orientation === 'vertical' && width > breakpoint) {
    tabsComponent = (
      <VerticalTabs format={format} data-testid={`building-block-vertical-tabs`}>
        {childBody}
      </VerticalTabs>
    );
  } else {
    tabsComponent = (
      <Pivot
        data-testid={`building-block-tab-${name}`}
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        id="pivot"
        styles={pivotStyles}
        onLinkClick={(item) => {
          onTabClick(id, item);
        }}
        overflowBehavior="menu"
        overflowAriaLabel="more items"
        selectedKey={getActiveTab(id)}
      >
        {childBody}
      </Pivot>
    );
  }

  return (
    <div ref={tabsParent} style={{ height: '100%', width: '100%' }}>
      {tabsComponent}
    </div>
  );
}
